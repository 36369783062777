import { omit } from 'lodash';
import { staticIDs } from '../common/constants';

export const getProductOptions = (productData) => {
  return productData.map((el) => {
    return {
      label: el?.name,
      value: el?._id,
      product: { ...el },
    };
  });
};

export const getCategoryOptions = (catData, productType) => {
  return catData
    .filter((el) => el.product_type === productType)
    .map((el) => {
      return {
        label: el?.name,
        value: el?._id,
      };
    });
};
export const questionType = [
  { label: 'Input', value: 'input' },
  { label: 'Yes or No', value: 'boolean' },
  { label: 'Multi Select', value: 'select' },
  { label: 'File', value: 'file' },
  { label: 'Date', value: 'date' },
];
export const inputBooleanQuestionType = [
  { label: 'Date', value: 'date' },
  { label: 'Input', value: 'input' },
  { label: 'Yes or No', value: 'boolean' },
  { label: 'File', value: 'file' },
];

export const inputBooleanQuestionTypeWithGP = [
  { label: 'Date', value: 'date' },
  { label: 'Input', value: 'input' },
  { label: 'Textarea', value: 'textarea' },
  { label: 'Yes or No', value: 'boolean' },
  { label: 'File', value: 'file' },
  { label: 'GP Select', value: 'gp_select' },
];

export const answerOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

export const booleanRestrictOptions = [
  { label: 'Yes-Restrict', value: 'yesRestrict' },
  { label: 'No-Restrict', value: 'noRestrict' },
  { label: 'Any', value: 'any' },
];
export const booleanRestrictAndCertainOptions = [
  { label: 'Yes-Restrict', value: 'yesRestrict' },
  { label: 'No-Restrict', value: 'noRestrict' },
  { label: 'Yes-Restrict Certain', value: 'yesCertainRestrict' },
  { label: 'No-Restrict Certain', value: 'noCertainRestrict' },
  { label: 'Any', value: 'any' },
];
export const booleanOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];
// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  let result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
export const move = (
  source,
  destination,
  droppableSource,
  droppableDestination,
) => {
  let sourceClone = Array.from(source);
  let destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export const grid = 8;

export const getItemStyle = (isDragging, draggableStyle, id) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging
    ? '#dcefff'
    : id
    ? id === staticIDs?.weight ||
      id === staticIDs?.height ||
      id === staticIDs?.gp_question
      ? '#ffc0cb9e'
      : '#eeeeee'
    : 'white',
  borderRadius: '10px',

  // styles we need to apply on draggables
  ...draggableStyle,
});

export const getListStyleSelected = (isDraggingOver) => ({
  background: isDraggingOver ? '#f6f6f6' : 'white',
  padding: grid * 4,
});

export const excludeCurrentProductFromList = (id, productList) => {
  if (!id) {
    return productList;
  }
  return productList.filter((el) => el.value !== id);
};

export const getProductPayload = (data) => {
  data?.brand?.__isNew__
    ? (data.new_brand = data?.brand?.label || null)
    : (data.brand = data?.brand?.value || null);
  // because product subcategory is an array and medication subcategory is an object
  data?.type === 'product'
    ? data?.sub_category?.length &&
      data?.sub_category.forEach((el, index) => {
        data[`sub_category[${index}]`] = el.value;
      })
    : (data[`sub_category[0]`] = data?.sub_category?.value);
  //
  if (data?.variant_list) {
    let tempVariantList = {};
    Object.keys(data?.variant_list)?.forEach((el, index) => {
      let tempValues = data?.variant_list[el]?.values.map((elem) => elem.value);
      tempVariantList[el] = {
        ...data.variant_list[el],
        priority: index + 1,
        values: tempValues,
      };
    });
    data.variant_list = JSON.stringify(tempVariantList);
  } else {
    data.variant_list = null;
  }
  data?.tick_points?.length &&
    data?.tick_points.forEach((el, index) => {
      data[`tick_points[${index}]`] = JSON.stringify(el);
    });
  //
  if (data.health_goal?.value) {
    data.health_goal_id = data?.health_goal?.value;
  }

  data.vat_rate = data?.vat_rate?.value;
  data?.ingrediant?.length &&
    data?.ingrediant.forEach((el, index) => {
      data[`ingrediant[${index}]`] = el.label;
    });
  data?.recommended_product?.length &&
    data?.recommended_product.forEach((el, index) => {
      data[`recommended_product[${index}]`] = el.value;
    });
  data?.faq?.length &&
    data?.faq.forEach((el, index) => {
      data[`faq[${index}]`] = JSON.stringify(el);
    });
  data?.landingpage_faq?.length &&
  data?.landingpage_faq.forEach((el, index) => {
    data[`landingpage_faq[${index}]`] = JSON.stringify(el);
  });
  let relatedKeywordIndex = 0;
  let newRelatedKeywordIndex = 0;
  data?.related_keyword?.length &&
    data?.related_keyword.forEach((el, index) => {
      if (el.__isNew__) {
        data[`new_related_keyword[${newRelatedKeywordIndex}]`] = el.label;
        newRelatedKeywordIndex = newRelatedKeywordIndex + 1;
      } else {
        data[`related_keyword[${relatedKeywordIndex}]`] = el.value;
        relatedKeywordIndex = relatedKeywordIndex + 1;
      }
      data[`related_keyword`];
    });
  data?.strengths?.length &&
    data?.strengths.forEach((el, index) => {
      let updatedObj = { ...el };
      if (
        data.is_subscribable &&
        el.subscription_price &&
        !(el.subscription_price.length === data.subscription_span.length)
      ) {
        let { subscription_price } = el;
        subscription_price = subscription_price.filter((elem) =>
          data.subscription_span.some((element) => element.value === elem.span),
        );
        subscription_price = subscription_price.map(
          ({ isDisabled, ...rest }) => rest,
        );
        updatedObj = {
          ...updatedObj,
          subscription_price,
        };
      }
      // convert the label value pair of attributes into simple strings
      const attributes = {};
      if (el.attributes) {
        Object.keys(el.attributes).forEach((elem) => {
          attributes[elem] = el.attributes[elem].value;
        });
        data[`strengths[${index}]`] = JSON.stringify({
          ...updatedObj,
          attributes,
        });
      } else {
        data[`strengths[${index}]`] = JSON.stringify(updatedObj);
      }
    });
  if (data?.follow_up_questions?.length) {
    data.follow_up_questions.forEach((el, index) => {
      data[`follow_up_questions[${index}]`] = JSON.stringify(el);
    });
  } else {
    delete data.follow_up_questions;
  }
  if (data?.subscription_span?.length) {
    data.subscription_span.forEach((el, index) => {
      data[`subscription_span[${index}]`] = JSON.stringify({
        ...el,
        span: el.value,
      });
    });

    // data.subscription_span = JSON.stringify(tempSubscriptionSpan);
  }

  let photosClone = [];
  let oldPhotos = [];
  data?.photos?.forEach((el) => {
    if (el?.alreadyExists) {
      oldPhotos.push(omit(el, ['alreadyExists', 'PrevUrl']));
    } else {
      photosClone.push(el.imgData);
    }
  });
  data.photos = photosClone;

  if (oldPhotos.length) {
    oldPhotos.forEach((el, index) => {
      data[`old_photos[${index}]`] = JSON.stringify(el);
    });
  }
  data?.landingpage_tick_points?.length &&
    data?.landingpage_tick_points.forEach((el, index) => {
      data[`landingpage_tick_points[${index}]`] = JSON.stringify(el);
    });
  const landingPageImage = [];
  data.isLandingpageImageUpdate = false;
  if (data.landingpage_image) {
    data.landingpage_image.forEach((el) => {
      if (el.location) landingPageImage.push(el);
      else {
        data.isLandingpageImageUpdate = true;
        landingPageImage.push(el.imgData);
      }
    });
  }
  data.landingpage_image = landingPageImage;
  if (data.new_brand) {
    delete data.brand;
  }
  delete data.follow_up_questions;
  delete data.stock;
  delete data.tick_points;
  delete data.landingpage_tick_points;
  delete data.sub_categories;
  delete data.strengths;
  delete data.faq;
  delete data.landingpage_faq;
  delete data.brandForRecSupp;
  delete data.ingrediant;
  delete data.variantAttributes;
  delete data.recommended_product;
  delete data.question_answer;
  delete data.related_keyword;
  delete data.category;
  delete data.sub_category;
  delete data.health_goal;
  return data;
};
