import React, {useState} from 'react';
import { Form, Formik } from 'formik';
import ImageInput from '../../../common/FormComponents/ImageInput';
import Input from '../../../common/FormComponents/Input';
import { Button, Spinner } from 'react-bootstrap';
import { DIMENSION } from '../../../common/constants';
import TextEditor from '../../../common/TextEditor';
import TextError from '../../../common/FormComponents/TextError';
import deleteIcon from '../../../assets/images/trash.svg';
import editIcon from '../../../assets/images/pencil.svg';
import ReactTextareaAutosize from 'react-textarea-autosize';

import { toastError } from '../../../common/UI/Toasts';

const LandingpageDetails = (props) => {
  const {
    productData,
    handlePrevious,
    handleSubmit,
    isSubmitDisabled,
    productId,
    validationSchema,
    handleChange,
    deleteFAQAndTickPoints
  } = props;

  const [currentFAQObj, setCurrentFAQObj] = useState({
    question: '',
    answer: '',
  });

  const [currentTickPoint, setCurrentTickPoint] = useState({
    value: '',
  });

  const setFAQEditValue = (index) => {
    setCurrentFAQObj({ ...productData?.landingpage_faq[index], index: index });
  };

  const setTickPointsEditValue = (index) => {
    setCurrentTickPoint({
      value: productData?.landingpage_tick_points[index],
      index: index,
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-12 mt-2 mb-4">
          <hr />
        </div>
        <div className="col-12">
          <h4 className="middle_title">Landing Page Details</h4>
        </div>
        <Formik
          initialValues={productData}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validateOnChange={false}
          validationSchema={validationSchema}
        >
          {(formik) => {
            return (
              <Form>
                <div className="middle_main_2">
                  <div className="row">
                    <div className="col-xl-3 col-md-3 col-lg-3 form-group">
                      <Input
                        label="Title"
                        labelClassname="form-label"
                        name="landingpage_title"
                        type="text"
                        className="form-control form-input"
                        placeholder="Title"
                        isRequired
                      />
                    </div>
                    <div className="col-xl-3 col-md-3 col-lg-3 form-group">
                      <Input
                        label="Shoparize Title"
                        labelClassname="form-label"
                        name="shoparize_title"
                        type="text"
                        className="form-control form-input"
                        placeholder="Shoparize Title"
                      />
                    </div>
                    <div className="col-xl-3 col-md-3 col-lg-3 form-group">
                      <Input
                        label="Shoparize Brand"
                        labelClassname="form-label"
                        name="shoparize_brand"
                        type="text"
                        className="form-control form-input"
                        placeholder="Shoparize Brand"
                      />
                    </div>
                    <div className="col-xl-3 col-md-3 col-lg-3 form-group">
                      <Input
                        label="Shoparize Product Type"
                        labelClassname="form-label"
                        name="shoparize_product_type"
                        type="text"
                        className="form-control form-input"
                        placeholder="Shoparize Product Type"
                      />
                    </div>
                    <div className="col-xl-3 col-md-3 col-lg-3 form-group">
                      <Input
                        label="Shoparize Condition"
                        labelClassname="form-label"
                        name="shoparize_condition"
                        type="text"
                        className="form-control form-input"
                        placeholder="Shoparize Condition"
                      />
                    </div>
                    <div className="col-xl-3 col-md-3 col-lg-3 form-group">
                      <Input
                        label="Shoparize Description"
                        labelClassname="form-label"
                        name="shoparize_description"
                        type="text"
                        className="form-control form-input"
                        placeholder="Shoparize Description"
                      />
                    </div>
                    <div className="col-xl-3 col-md-3 col-lg-3 form-group">
                      <Input
                        label="Shoparize Price"
                        labelClassname="form-label"
                        name="shoparize_price"
                        type="text"
                        className="form-control form-input"
                        placeholder="Price"
                      />
                    </div>
                    <div className="col-xl-3 col-md-3 col-lg-3 form-group">
                      <Input
                        label="Shoparize Availability"
                        labelClassname="form-label"
                        name="shoparize_availability"
                        type="text"
                        className="form-control form-input"
                        placeholder="Shoparize Availability"
                      />
                    </div>
                    <div className="col-xl-3 col-md-3 col-lg-3 form-group">
                      <Input
                        label="Shoparize GTIN"
                        labelClassname="form-label"
                        name="shoparize_gtin"
                        type="text"
                        className="form-control form-input"
                        placeholder="Shoparize GTIN"
                      />
                    </div>
                    <div className="col-xl-3 col-md-3 col-lg-3 form-group">
                      <Input
                        label="Shoparize Gender"
                        labelClassname="form-label"
                        name="shoparize_gender"
                        type="text"
                        className="form-control form-input"
                        placeholder="Shoparize Gender"
                      />
                    </div>
                    <div className="col-xl-3 col-md-3 col-lg-3 form-group">
                      <Input
                        label="Shoparize Category"
                        labelClassname="form-label"
                        name="shoparize_category"
                        type="text"
                        className="form-control form-input"
                        placeholder="Shoparize Category"
                      />
                    </div>
                    <div className="col-xl-3 col-md-3 col-lg-3 form-group">
                      <Input
                        label="Shoparize Sale Price"
                        labelClassname="form-label"
                        name="shoparize_sales_price"
                        type="text"
                        className="form-control form-input"
                        placeholder="Shoparize Sale Price"
                      />
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-xl-8 form-group">
                        <label className="form-label">
                          Description
                          <span className="text-danger">*</span>
                        </label>
                        <TextEditor
                          name="landingpage_description"
                          value={formik.values.landingpage_description}
                          className={'position-relative'}
                          onEditorChange={(value) => {
                            formik.setFieldValue(
                              'landingpage_description',
                              value,
                              true,
                            );
                          }}
                          editorHeight="30vh"
                        />
                        {formik.touched['landingpage_description'] &&
                          formik.errors['landingpage_description'] && (
                            <TextError>
                              {formik.errors['landingpage_description']}
                            </TextError>
                          )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mt-1 mb24">
                        <hr />
                      </div>
                      <div className="col-12">
                        <h4 className="middle_title">
                          Image
                          <span className="text-danger ml-1">*</span>
                        </h4>
                      </div>
                    </div>
                    <ImageInput
                      label={`Upload Image (w x h: ${DIMENSION.healthGoal.width} x ${DIMENSION.healthGoal.height})`}
                      accept="image/png, image/svg, image/jpeg, image/jpg, image/PNG, image/SVG, image/JPEG, image/JPG, image/webp"
                      name={'landingpage_image'}
                      id={'landingpage_image'}
                      imagesLimit={1}
                      productId={productId}
                      imageDimention={DIMENSION.healthGoal}
                      isRequired
                    />
                  </div>
                </div>{' '}
                
                <div className="col-12 mt-2">
                  <div className="d-flex align-items-center">
                    <label className="form-label" htmlFor={'landingpage_faq'}>
                      Add Landing Page FAQs:
                    </label>
                    <Button
                      className="ms-4"
                      onClick={() => {
                        if (
                          currentFAQObj?.question !== '' &&
                          currentFAQObj?.answer !== ''
                        ) {
                          handleChange('landingpage_faq', currentFAQObj);
                          setCurrentFAQObj({
                            question: '',
                            answer: '',
                          });
                        } else {
                          toastError('Question and Answer both are required.');
                        }
                      }}
                    >
                      Save Changes
                    </Button>
                  </div>
                  <div className="ms-4">
                    <div className="d-flex mt-3">
                      <label
                        className="form-label mb-0"
                        style={{
                          fontSize: '0.8rem',
                        }}
                      >
                        Question
                      </label>
                      <ReactTextareaAutosize
                        type="text"
                        className="form-control ms-3 w-50"
                        placeholder=""
                        onChange={(e) =>
                          setCurrentFAQObj((prev) => {
                            return {
                              ...prev,
                              question: e.target.value,
                            };
                          })
                        }
                        value={currentFAQObj?.question}
                        minRows={1}
                      />
                    </div>
                    <div className="d-flex mt-3">
                      <label
                        className="form-label mb-0"
                        style={{
                          fontSize: '0.8rem',
                        }}
                      >
                        Answer
                      </label>
                      <TextEditor
                        className={'w-50 ms-4'}
                        onEditorChange={(value) => {
                          setCurrentFAQObj((prev) => {
                            return {
                              ...prev,
                              answer: value,
                            };
                          });
                        }}
                        value={currentFAQObj?.answer}
                        editorHeight="30vh"
                      />
                    </div>
                  </div>
                  {productData?.landingpage_faq?.length
                    ? productData?.landingpage_faq?.map((el, index) => {
                        return (
                          <>
                            {el?.question != '' ? (
                              <React.Fragment key={index}>
                                <div className="mb-3 w-100">
                                  <div className="d-flex justify-content-between">
                                    <label
                                      className="form-label mb-0 fs-18 w-100"
                                      style={{
                                        wordWrap: 'break-word',
                                      }}
                                    >
                                      Q) {el?.question}
                                    </label>
                                    <div className="d-flex">
                                      <span onClick={() => setFAQEditValue(index)}>
                                        <img
                                          src={editIcon}
                                          alt="pencil"
                                          width="20"
                                          height="20"
                                        />
                                      </span>
                                      <span
                                        onClick={() => {
                                          if (
                                            (currentFAQObj?.index ||
                                              currentFAQObj?.index === 0) &&
                                            currentFAQObj?.index === index
                                          )
                                            setCurrentFAQObj({
                                              question: '',
                                              answer: '',
                                            });
                                          deleteFAQAndTickPoints('landingpage_faq', index);
                                        }}
                                      >
                                        <img
                                          src={deleteIcon}
                                          alt="trash"
                                          width="20"
                                          height="20"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      backgroundColor: '#d3d3d359',
                                      borderRadius: '5px',
                                      padding: '10px',
                                      wordWrap: 'break-word',
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: el?.answer,
                                    }}
                                    className="w-100"
                                  ></div>
                                </div>
                              </React.Fragment>
                            ) : null}
                          </>
                        );
                      })
                    : null}
                </div>
                <div className="col-12 mt-2">
                  <label
                    className="form-label mb-0"
                    style={{
                      fontSize: '1rem',
                    }}
                  >
                    Insert Landing Page Tick Points:
                  </label>
                  <input
                    type={'text'}
                    className="form-input ms-2"
                    value={currentTickPoint?.value}
                    onChange={(e) =>
                      setCurrentTickPoint((prev) => {
                        return { ...prev, value: e.target.value };
                      })
                    }
                  />
                  <Button
                    className="order_btns ms-2"
                    onClick={() => {
                      if (currentTickPoint?.value !== '') {
                        handleChange('landingpage_tick_points', currentTickPoint);
                        setCurrentTickPoint({ value: '' });
                      } else {
                        toastError('Please add point');
                      }
                    }}
                  >
                    Add
                  </Button>
                  <div className="row mt-3 d-flex">
                    {productData?.landingpage_tick_points?.length
                      ? productData?.landingpage_tick_points?.map((el, index) => {
                          return (
                            <>
                              {el?.question != '' ? (
                                <React.Fragment key={index}>
                                  <div
                                    className="d-flex justify-content-between mb-3"
                                    style={{
                                      width: 'fit-content',
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor: '#d3d3d359',
                                        borderRadius: '5px',
                                        padding: '10px',
                                        wordWrap: 'break-word',
                                      }}
                                      onClick={() => setTickPointsEditValue(index)}
                                      // className="w-100"
                                    >
                                      {el}
                                    </div>
                                    <div
                                      className="d-flex align-items-center justify-content-center ms-1"
                                      style={{
                                        width: '42px',
                                        backgroundColor: '#d78c8c59',
                                        borderRadius: '30px',
                                      }}
                                      onClick={() => {
                                        if (
                                          (currentTickPoint?.index ||
                                            currentTickPoint?.index === 0) &&
                                          currentTickPoint?.index === index
                                        )
                                          setCurrentTickPoint({
                                            value: '',
                                          });
                                        deleteFAQAndTickPoints('landingpage_tick_points', index);
                                      }}
                                    >
                                      {/* <span
                                          onClick={() => setTickPointsEditValue(index)}
                                        >
                                          <img
                                            src={editIcon}
                                            alt="pencil"
                                            width="20"
                                            height="20"
                                          />
                                        </span> */}
                                      <span>
                                        <img
                                          src={deleteIcon}
                                          alt="trash"
                                          width="20"
                                          height="20"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ) : null}
                            </>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div
                  className="footer_main"
                  style={{ justifyContent: 'space-between' }}
                >
                  <div>
                    <button
                      onClick={() => handlePrevious()}
                      type="button"
                      className="btn btn-sky my-2 me-2"
                    >
                      Previous
                    </button>
                  </div>
                  <button
                    disabled={isSubmitDisabled}
                    type="submit"
                    className="btn btn-success my-2 me-2"
                  >
                    {isSubmitDisabled && (
                      <span className="submit-loader">
                        <Spinner animation="border" size="sm" />
                      </span>
                    )}{' '}
                    Submit
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default LandingpageDetails;
